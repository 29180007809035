<template>
  <div class="login-success">
    <Container>
      <Card padding="large">
        <b-row class="justify-content-center">
          <b-col cols="auto">
            <Loader :text="$t('LOADING')" />
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_LOGIN_INFO } from '@/types';
import { Loader, Container, Card } from '@/components';

export default {
  name: 'Success',
  components: {
    Loader,
    Container,
    Card,
  },
  mounted() {
    this.GET_LOGIN_INFO();
  },
  methods: {
    ...mapActions([GET_LOGIN_INFO]),
  },
};
</script>
